<template>
  <div :class="{ 'c-dark-theme': true }">
    <BCard style="min-width:440px;max-width:500px" :border-variant="signalColor">
      <div slot="header">
<!--        <BIconOctagonFill  class="mr-1"/>-->
        <BBadge :variant="signalColor"> {{tank.tankCode}} </BBadge>
        <BBadge :variant="signalColor" class="ml-1 mr-1" style="min-width:100px"> {{tank.name}} </BBadge>
        <BBadge class="mr-1" :style="{backgroundColor: oilColors[tank.oilCode], color: '#222222'}">
          {{ oilCodes[tank.oilCode] }}
        </BBadge>
        <BBadge :variant="statusVariant" :class="{ 'blink': statusVariant!=='info'}">{{ statusMsg }}</BBadge>

        <div class="card-header-actions">
          <BRow>
            <BCol v-if="tank.tankUse==='1'">
              <BButtonGroup size="sm">
                <BButton class="mr-1" variant="outline-danger" @click="setInout('I')" :disabled="inout.sts==='O'">
                  {{ inout.sts==='I'?`입고종료`:`입고시작` }}
                </BButton>
                <BButton variant="outline-warning" @click="setInout('O')" :disabled="inout.sts==='I'">
                  {{ inout.sts==='O'?`출고종료`:`출고시작` }}
                </BButton>
              </BButtonGroup>
            </BCol>
            <BCol v-if="tank.tankShape==='4' && inout.sts!=='N'">
              <BBadge class="mr-1"> 기종 </BBadge> {{inout.eqnm}}
              <BBadge class="ml-2 mr-1"> 기번 </BBadge> {{inout.eqid}}
            </BCol>

          </BRow>

        </div>
      </div>

      <!--  Inout progress bar -- BEGIN -->
      <BRow v-if="inout.sts!=='N'" class="mb-2">
        <BCol sm="4" class="p-0">
          <BProgress :max="100" height="2rem" show-value>
            <BProgressBar :value="volPercent"
                          :animated="true"
                          :variant="progressVariant(volPercent)"/>
          </BProgress>
        </BCol>
        <BCol class="p-0 ml-1">
          <BInputGroup size="sm" class="small">
            <BButton size="sm" variant="outline-danger"  style="min-width:100px">
              <strong :class="{blink: true}">{{ trans(diffOvm, 'v', volUnit) }}</strong>
            </BButton>

            <BFormInput v-if="tank.tankShape!=='4'"
                        size="sm" :placeholder="`기준량(${unitSign(volUnit)}) 설정`" type="number"
                        v-model="inputTargetVol">
            </BFormInput>

            <BInputGroupAppend v-if="tank.tankShape!=='4'">
              <BButton variant="success" @click="setInoutVolume"><BIconSave2/></BButton>
              <BButton variant="danger" @click="setInout('N')"><BIconTrash2/></BButton>
            </BInputGroupAppend>

            <BInputGroupAppend class="ml-2" v-if="tank.tankShape==='4' && pk.fcd==='04'">
              <BInputGroupText>인증#</BInputGroupText>
              <BInputGroupText>{{inout.ano}}</BInputGroupText>
            </BInputGroupAppend>
          </BInputGroup>

        </BCol>
      </BRow>
      <!--  Inout progress bar -- END -->




      <BRow>
        <BCol class="p-0 mb-1 text-center">
          <BButtonGroup size="sm">
            <BButton variant="info" @click="toggleUnit">용량 <BBadge>{{ unitSign(volUnit) }}</BBadge> </BButton>
            <BButton variant="outline-info" class="text-right" style="min-width:80px" >
              <b>{{ trans(tank.tankRealVol, 'v', volUnit) }}</b>
            </BButton>
            <BButton variant="info">유량 <BBadge>{{ unitSign(volUnit) }}</BBadge></BButton>
            <BButton variant="outline-info" class="text-right" style="min-width:80px">
              <b>{{ trans(pk.ovm, 'v', volUnit) }}</b>
            </BButton>
            <BButton variant="info">충족율 </BButton>
            <BButton variant="outline-info" class="text-right" style="min-width:60px">
              {{ comma(pk.tvp,0) }} %
            </BButton>
          </BButtonGroup>
          <!--// 1-넘침, 2-부족, 3-화재, 4-수분, 5-누유, 6-넘침2, 7-부족2, 8-센서오류, 9-패킷오류-->
          <WidgetEvents class="mt-1" :events="events" :key="eventKey"/>

        </BCol>
      </BRow>




      <BRow>
        <!-- canvas -->
        <BCol class="content-center">
          <br>
          <canvas
            width="150"
            height="170"
            ref="canvas"
          >
          </canvas>
        </BCol>

        <BCol>
          <BTableSimple small class="float-right small text-nowrap text-right font-weight-bolder"
                        style="width:120px">
            <BTr>
              <BTd style="min-width:50px">유위 <BBadge>{{ unitSign(lenUnit) }}</BBadge></BTd>
              <BTd style="min-width:70px"><BBadge :variant="colors.text.oil[status]">{{ trans(pk.ohr,'l',lenUnit) }}</BBadge></BTd>
            </BTr>
            <BTr>
              <BTd>수위 <BBadge>{{ unitSign(lenUnit) }}</BBadge></BTd>
              <BTd><BBadge :variant="colors.text.wtr[status]">{{ trans(pk.whr,'l',lenUnit) }}</BBadge> </BTd>
            </BTr>
            <BTr>
              <BTd>측정유량 <BBadge>{{ unitSign(volUnit) }}</BBadge></BTd>
              <BTd> <BBadge :variant="colors.text.oil[status]">{{ trans(pk.ovm, 'v', volUnit) }}</BBadge> </BTd>
            </BTr>
            <BTr>
              <BTd>환산유량 <BBadge>{{ unitSign(volUnit) }}</BBadge></BTd>
              <BTd><BBadge>{{ trans(pk.ovc,'v', volUnit) }}</BBadge></BTd>
            </BTr>
            <BTr>
              <BTd>보정유량 <BBadge>{{ unitSign(volUnit) }}</BBadge></BTd>
              <BTd><BBadge>{{ trans(pk.ovcr,'v', volUnit) }}</BBadge></BTd>
            </BTr>
            <BTr>
              <BTd>수분량 <BBadge>{{ unitSign(volUnit) }}</BBadge></BTd>
              <BTd><BBadge> {{ trans(pk.wv, 'v', volUnit) }}</BBadge></BTd>
            </BTr>
            <BTr>
              <BTd>평균 <BBadge>{{ unitSign(tmpUnit) }}</BBadge></BTd>
              <BTd><BBadge> {{ trans(pk.avgTm, 't', tmpUnit) }} </BBadge></BTd>
            </BTr>
            <BTr>
              <BTd colspan="2">
                <BButtonGroup>
                  <BButton @click="toggleDetail" class="small" size="sm" variant="outline-info">온도센서</BButton>
                  <BButton @click="toggleUnit" class="small ml-2" size="sm" variant="outline-info">단위변경</BButton>
                </BButtonGroup>
              </BTd>
<!--              <BTd>-->
<!--                <BBadge>{{ pk.useTms.toString() }}</BBadge>-->
<!--              </BTd>-->
            </BTr>
          </BTableSimple>
        </BCol>
      </BRow>
      <BCard v-show="detailTmShow" no-body>
        <BTableSimple small class="small text-nowrap">
          <BTr class="text-center">
            <BTd>온도1</BTd>
            <BTd>온도2</BTd>
            <BTd>온도3</BTd>
            <BTd>온도4</BTd>
            <BTd>온도5</BTd>
            <BTd>온도6</BTd>
          </BTr>
          <BTr class="text-center font-weight-bolder">
            <BTd>{{ trans( pk.tm1, 't', tmpUnit)  }}</BTd>
            <BTd>{{ trans( pk.tm2, 't', tmpUnit)  }}</BTd>
            <BTd>{{ trans( pk.tm3, 't', tmpUnit)  }}</BTd>
            <BTd>{{ trans( pk.tm4, 't', tmpUnit)  }}</BTd>
            <BTd>{{ trans( pk.tm5, 't', tmpUnit)  }}</BTd>
            <BTd>{{ trans( pk.tm6, 't', tmpUnit)  }}</BTd>
          </BTr>
        </BTableSimple>
        <BTableSimple small class="small text-nowrap">
          <BTr class="text-center"><BTd>평균온도(C)</BTd><BTd>평균온도(F)</BTd><BTd>최고온도</BTd><BTd>최저온도</BTd></BTr>
          <BTr class="text-center font-weight-bold">
            <BTd>{{ pk.avgTm }}</BTd>
            <BTd>{{ pk.avgTmF }}</BTd>
            <BTd>{{ pk.maxTm }}</BTd>
            <BTd>{{ pk.minTm }}</BTd>
          </BTr>
        </BTableSimple>
      </BCard>
    </BCard>
  </div>
</template>

<style>
.tooltip .tooltip-inner {
  text-align: left;
  max-width: 350px !important;
  width: 250px !important;
}

@keyframes blink-effect {
  50% {
    opacity: 0;
  }
}

.blink {
  animation: blink-effect 1s step-end infinite;

  /*
  animation-name: blink-effect;
  animation-duration: 1s;
  animation-iteration-count:infinite;
  animation-timing-function:step-end;
  */
}
</style>

<script>
//------------------------------------------------------------------------
// import '../../common/HelperMixin';
import WidgetEvents from '../widget/WidgetEvents'
import {fabric} from 'fabric';
// import moment from 'moment';
import {
  // getHexColor,
  getR,
  clone,
  comma,
  trans,
  apiCall,
  unitSign,
  random,
  speech
} from "@/common/utils";
import {PacketSchema} from "@/common/schema";
import {IoStsMap, IoStsVariant} from '@/common/constants';


const _normalBadge = {'1':'dark', '2':'dark', '3':'dark', '4':'dark', '5':'dark', '6':'dark', '7':'dark', '8':'dark', '9':'dark'};
const GL2LTR = 3.78541;
const LTR2GL = 0.264172;

export default {
  name: "Tank2D",
  components: {
    WidgetEvents
  },
  props: {
    width: {type: Number, default: 300},
    height: {type: Number, default: 200},
    tank: { type: Object, default: null },
    bgColor: { type: String, default: '#FFFFFF'},
    tlgPacket : { type: Object, default: null },
    initPacket : { type: Object, default: null }
  },
// °C, °F , ㎜, in, gal ====> C,F,mm,in,gal
  data () {
    return {
      tankSts: { io: '', ioAt: null },
      canvas: null,
      windowHeight: 0,
      windowWidth: 0,

      detailTmShow: false,

      eventKey: 0,
      statusMsg: 'wait.',
      statusVariant: 'dark',

      tid: '',
      tnm: '',
      inout: { sts: 'N' },

      events: {data: [], dt: null},
      // eventTip: {},
      // eventLogs: [],
      trans: trans,
      unitSign: unitSign,
      volUnit: 'g', // l <--> g
      lenUnit: 'i', // mm <--> i
      tmpUnit: 'f', // c <---> f
      comma: comma,
      // 1-넘침,2-부족,3-화재,4-수분,5-누유,6-넘침2,7-부족2,8-센서오류,9-패킷오류
      eventColor: {
        '1':'warning',
        '2':'warning',
        '3':'danger',
        '4':'info',
        '5':'danger',
        '6':'danger',
        '7':'danger',
        '8':'info',
        '9':'warning'
      },
      badgeColor: clone(_normalBadge),
      colors: {
        pipe: '#F0F0F0',
        water: '#0000F0',
        sensor: {
          off: '#606050',
          on: '#16A2B8',
          warn: '#FFC008',
          info: '#047BFF',
          danger: '#DC3545',
          unknown: '#FF00FF',
          error: '#FF0000'
        },
        tm: {
          off: '#001233',
          norm: '#047BFF',
          error: '#DC3545'
        }, // 온도계 온도

        text: {
          oil: { norm:'success', warn:'danger', info: 'info' },
          wtr: { norm:'secondary', warn:'primary', info: 'info' },
          in : 'warning',
          out: 'dark',
        }
      },

      status: 'off', // norm, warn, info, off
      signalColor: 'dark',
      pk: clone(PacketSchema),
      w: 400,
      h: 250,
      cube: null,

      isPacketFirst: true,
      isCamMoved: false,

      controls: null,

      oilColors: this.$store.state.codeMaps['OIL_COLOR'],
      oilCodes: this.$store.state.codeMaps['OIL_CODE'],
      nozSizeMap: this.$store.state.codeMaps['NOZ_SIZE'],
      shapeMap: this.$store.state.codeMaps['TANK_SHAPE'],
      eventCodeMap: this.$store.state.codeMaps['EVENT'],

      machine: null,
      tankColor: null,
      oilColor: null,

      tankOpenAngle: getR(45),
      tankCloseAngle: getR(280),

      oilTopEllipse: null,
      oilBottomEllipse: null,
      waterTopEllipse: null,
      waterBottomEllipse: null,
      oilRect: null,
      waterRect: null,


      oilVol: 0,
      wtrVol: 0,
      diffOvm: 0, // inout differences
      targetVol: 0, // inout differences
      inputTargetVol: null,
      volPercent: 0,
    }

  },
  created(){
    console.log("--- Tank2D --- created--------------------- shape = ", this.tank.tankShape);
    this.volUnit = this.tank.unitOfVol?this.tank.unitOfVol:'l'
    this.lenUnit = this.tank.unitOfLen?this.tank.unitOfLen:'mm'
    this.tmpUnit = this.tank.unitOfTmp?this.tank.unitOfTmp:'c'
  },

  computed: {},

  async mounted() {
    try{
      this.w = this.width;
      this.h = this.height;

      this.isPacketFirst = true;
      this.isCamMoved = false;

      if( this.tank ) {
        this.tid = this.tank.tid;
        this.tnm = this.tank.name;

        this.oilColor = this.oilColors[this.tank.oilCode];
        this.init(this.tank);

        await this.getInout();
        await this.getTankSts();
      }
      this.oilVol = this.tank.tankRealVol;

    }catch(err){
      console.log(err);
    }

  },


  methods: {
    progressVariant(v){
      if(!v) return 'dark';

      if(v<10) return 'info';
      else if(v < 40) return 'primary';
      else if(v < 80) return 'success';
      else if(v < 95) return 'warning';
      else if(v >= 95) return 'danger';
      else return 'info';
    },

    toggleUnit(){
      if(this.volUnit==='l') this.volUnit='g'
      else this.volUnit='l';

      if(this.lenUnit==='mm') this.lenUnit='i'
      else this.lenUnit='mm';

      if(this.tmpUnit==='c') this.tmpUnit = 'f';
      else this.tmpUnit='c';
    },

    init(tank){
      // console.log( "init tank ----------->", tank );
      const canvas = new fabric.Canvas( this.$refs.canvas );
      this.canvas = canvas;

      let oilVol = 0;
      let wtrVol = tank.tankRealVol;

      //탱크 유량 및 수분량 설정
      let oilHeight = oilVol / tank.tankRealVol * 100;
      let waterHeight = wtrVol / tank.tankRealVol * 100;

      let sumHeight =  oilHeight + waterHeight;

      let oilTop = 157 - sumHeight;
      let waterTop = oilTop + oilHeight;

      let oilEllipseY = 10;
      let waterEllipseTopY = 0;
      let waterEllipseBottomY = 10;

      //유량 및 수분량이 0일때 처리
      if(oilHeight === 0) { oilEllipseY = 0; waterEllipseTopY = 10; }
      if(waterHeight === 0) { waterEllipseTopY = 0; waterEllipseBottomY = 0; }

      //탱크종류별 이미지 설정

      const leftLocation = 16;

      // 오일 Rect
      this.oilRect = new fabric.Rect({
        left: leftLocation,
        top: oilTop,       //최소 157, 최대 57
        fill: tank.color,
        width: 105,
        height: oilHeight,
        opacity: 0.8,
        selectable: false
      });

      // 오일 상단
      this.oilTopEllipse = new fabric.Ellipse({
        left: leftLocation,
        top: oilTop - 10,       //최소 147, 최대 47
        //fill: oilColor,
        rx: 52.5,
        ry: oilEllipseY,
        opacity: 0.9,
        selectable: false
      });


      this.oilTopEllipse.set('fill', new fabric.Gradient({
        coords: {
          x1: 0,
          y1: -1*this.oilTopEllipse.height / 5,
          x2: 0,
          y2: -1*this.oilTopEllipse.height * 1.5,
        },
        colorStops: [
          { offset: 0.3, color: tank.color },
          { offset: 1,  color: '#ddd'}
        ]
      })
      );


      // 오일 하단
      this.oilBottomEllipse = new fabric.Ellipse({
        left: leftLocation,
        top: waterTop - 10,       //최소 147, 최대 47
        fill: tank.color,
        rx: 52.5,
        ry: oilEllipseY,
        opacity: 0.9,
        selectable: false
      });

      // 수분 하단
      this.waterBottomEllipse = new fabric.Ellipse({
        left: leftLocation,
        top: 147,       //최소 147, 최대 47
        fill: '#367DB0',
        rx: 52.5,
        ry: waterEllipseBottomY,
        opacity: 0.9,
        selectable: false
      });


      // 수분 상단
      this.waterTopEllipse = new fabric.Ellipse({
        left: leftLocation,
        top: waterTop - 10,       //최소 147, 최대 47
        fill: '#367DB0',
        rx: 52.5,
        ry: waterEllipseTopY,
        opacity: 0.9,
        selectable: false
      });


      this.waterTopEllipse.set( 'fill',  new fabric.Gradient( {
        // type: 'linear',
        // gradientUnits: 'pixels',
        coords: {
          x1: 0,
          y1: -1 * this.waterTopEllipse.height / 5,
          x2: 0,
          y2: -1 * this.waterTopEllipse.height * 1.5,
        },
        colorStops: [
          { offset: 0.3, color: '#367DB0' },
          { offset: 1, color:'#ddd'}
        ]
      }));


      // 수분 Rect
      this.waterRect = new fabric.Rect({
        left: leftLocation,
        top: waterTop,       //최소 157, 최대 57
        fill: '#367DB0',
        width: 105,
        height: waterHeight,
        opacity: 0.9,
        selectable: false
      });

      const waterBottomEllipse = this.waterBottomEllipse;
      const waterRect = this.waterRect;
      const waterTopEllipse = this.waterTopEllipse;
      const oilBottomEllipse = this.oilBottomEllipse;
      const oilRect = this.oilRect;
      const oilTopEllipse = this.oilTopEllipse;
      let bgImageBack = '';
      let bgImageFront = '';
      let backImg;

      if( tank.tankShape === '2' || tank.tankShape === '4' ) {
        // Cylinder, TankLorry
        bgImageBack =  "/img/tank/CYLINDER_n130_b-black.png";
        bgImageFront = "/img/tank/CYLINDER_n130-black-new1.png";
      }else{
        // Exclusive, ConeRoof
        bgImageBack =  "/img/tank/EXCLUSIVE2_n130_b.png";
        bgImageFront = "/img/tank/EXCLUSIVE2_n130.png";
      }

      // 탱크 back 이미지 호출
      fabric.Image.fromURL( bgImageBack, function(oImg) {
        oImg.top = 20;
        oImg.left = 10;
        oImg.selectable = false;
        backImg = oImg;

        // 탱크 front 이미지 호출 및 캔버스 렌더링
        fabric.Image.fromURL( bgImageFront, function(oImg) {
          oImg.top = 20;
          oImg.left = 10;
          oImg.selectable = false;

          canvas.add(backImg);
          canvas.add(waterBottomEllipse);
          canvas.add(waterRect);
          canvas.add(waterTopEllipse);
          canvas.add(oilBottomEllipse);
          canvas.add(oilRect);
          canvas.add(oilTopEllipse);
          canvas.add(oImg);
        });
      });
    },

    renderVolume(oilVol, wtrVol){
      const canvas = this.canvas;
      // if(this.renderer ) this.renderer.render( this.scene, this.camera );
      canvas.remove(this.waterBottomEllipse);
      canvas.remove(this.waterRect);
      canvas.remove(this.waterTopEllipse);
      canvas.remove(this.oilBottomEllipse);
      canvas.remove(this.oilRect);
      canvas.remove(this.oilTopEllipse);

      //탱크 유량 및 수분량 설정
      let oilHeight = oilVol / this.tank.tankRealVol * 100;
      let waterHeight = wtrVol / this.tank.tankRealVol * 100;

      let sumHeight =  oilHeight + waterHeight;

      let oilTop = 157 - sumHeight;
      let waterTop = oilTop + oilHeight;

      let oilEllipseY = 10;
      let waterEllipseTopY = 0;
      let waterEllipseBottomY = 10;

      //유량 및 수분량이 0일때 처리
      if(oilHeight === 0) { oilEllipseY = 0; waterEllipseTopY = 10; }
      if(waterHeight === 0) { waterEllipseTopY = 0; waterEllipseBottomY = 0; }

      //탱크종류별 이미지 설정

      const leftLocation = 16;



      // 오일 Rect
      this.oilRect = new fabric.Rect({
        left: leftLocation,
        top: oilTop,       //최소 157, 최대 57
        fill: this.oilColor,
        width: 105,
        height: oilHeight,
        opacity: 0.8,
        selectable: false
      });

      // 오일 상단
      this.oilTopEllipse = new fabric.Ellipse({
        left: leftLocation,
        top: oilTop - 10,       //최소 147, 최대 47
        fill: this.oilColor,
        rx: 52.5,
        ry: oilEllipseY,
        opacity: 0.9,
        selectable: false
      });

      this.oilTopEllipse.set('fill', new fabric.Gradient({
          coords: {
            x1: 0,
            y1: -1*this.oilTopEllipse.height / 5,
            x2: 0,
            y2: -1*this.oilTopEllipse.height * 1.5,
          },
          colorStops: [
            { offset: 0.3, color: this.oilColor },
            { offset: 1,  color: '#ddd'}
          ]
        })
      );

      // 오일 하단
      this.oilBottomEllipse = new fabric.Ellipse({
        left: leftLocation,
        top: waterTop - 10,       //최소 147, 최대 47
        fill: this.oilColor,
        rx: 52.5,
        ry: oilEllipseY,
        opacity: 0.9,
        selectable: false
      });

      // 수분 하단
      this.waterBottomEllipse = new fabric.Ellipse({
        left: leftLocation,
        top: 147,       //최소 147, 최대 47
        fill: '#367DB0',
        rx: 52.5,
        ry: waterEllipseBottomY,
        opacity: 0.9,
        selectable: false
      });

      // 수분 상단
      this.waterTopEllipse = new fabric.Ellipse({
        left: leftLocation,
        top: waterTop - 10,       //최소 147, 최대 47
        fill: '#367DB0',
        rx: 52.5,
        ry: waterEllipseTopY,
        opacity: 0.9,
        selectable: false
      });

      this.waterTopEllipse.set( 'fill',  new fabric.Gradient( {
        // type: 'linear',
        // gradientUnits: 'pixels',
        coords: {
          x1: 0,
          y1: -1 * this.waterTopEllipse.height / 5,
          x2: 0,
          y2: -1 * this.waterTopEllipse.height * 1.5,
        },
        colorStops: [
          { offset: 0.3, color: '#367DB0' },
          { offset: 1, color:'#ddd'}
        ]
      }));


      // 수분 Rect
      this.waterRect = new fabric.Rect({
        left: leftLocation,
        top: waterTop,       //최소 157, 최대 57
        fill: '#367DB0',
        width: 105,
        height: waterHeight,
        opacity: 0.9,
        selectable: false
      });

      const waterBottomEllipse = this.waterBottomEllipse;
      const waterRect = this.waterRect;
      const waterTopEllipse = this.waterTopEllipse;
      const oilBottomEllipse = this.oilBottomEllipse;
      const oilRect = this.oilRect;
      const oilTopEllipse = this.oilTopEllipse;

      let backImg;
      let bgImageBack = '';
      let bgImageFront = '';

      if( this.tank.tankShape === '2' || this.tank.tankShape === '4' ){
        // Cylinder, tank lorry
        bgImageBack =  "/img/tank/CYLINDER_n130_b-black.png";
        bgImageFront = "/img/tank/CYLINDER_n130-black-new1.png";
      }else{
        // Exclusive, ConeRoof
        bgImageBack =  "/img/tank/EXCLUSIVE2_n130_b.png";
        bgImageFront = "/img/tank/EXCLUSIVE2_n130.png";
      }


      // 탱크 back 이미지 호출
      fabric.Image.fromURL( bgImageBack, function(oImg) {
        oImg.top = 20;
        oImg.left = 10;
        oImg.selectable = false;
        backImg = oImg;

        // 탱크 front 이미지 호출 및 캔버스 렌더링
        fabric.Image.fromURL( bgImageFront, function(oImg) {
          oImg.top = 20;
          oImg.left = 10;
          oImg.selectable = false;

          canvas.add(backImg);
          canvas.add(waterBottomEllipse);
          canvas.add(waterRect);
          canvas.add(waterTopEllipse);
          canvas.add(oilBottomEllipse);
          canvas.add(oilRect);
          canvas.add(oilTopEllipse);
          canvas.add(oImg);
        });
      });
    },

    /**
     * call from outside component
     * @param data
     */
    setVolume(data){
      try {
        const tp = data.payload;

        this.pk = tp;

        this.badgeColor = {'1':'dark', '2':'dark', '3':'dark', '4':'dark', '5':'dark', '6':'dark', '7':'dark', '8':'dark', '9':'dark'};
        this.status = 'on'

        if (tp.isEvt || tp.isErr) {
          this.status = 'warn';
          this.signalColor = tp.isErr ? 'danger' : 'warning';
          this.events.data = tp.events;
          this.events.dt = tp.dt;
        }else{
          this.events.data = [];
          this.events.dt = tp.dt;
          this.signalColor = 'info';
        }
        this.eventKey = random(1,10000);

        console.log( '----- signalColor ------------> ', tp );

        this.renderVolume(tp.ovm, tp.wv);

        this.setTankStatus( tp.sts );

        if( tp.sts!=='N' && tp.sts!=='F'){
          this.setInoutSts( data.inout );
        }

        setTimeout(() => {
          this.signalColor = "dark"
        }, 1500);
      }catch(err){
        console.log(err);
      }
    },

    setTankStatus(sts){
      this.tankSts = sts;
      const {io} = sts;
      this.sts = io;
      this.statusMsg = IoStsMap[io];
      this.statusVariant = IoStsVariant[io];
      console.log( 'setTankStatus --- inout ---> ',  sts, this.statusMsg);
    },


    setInoutSts(inout){
      console.log( 'setInoutSts ---------', inout );
      this.inout = inout;
      const {sts} = inout;
      this.statusMsg = IoStsMap[sts];
      switch(sts){
        case 'R':
          this.statusVariant = 'danger';
          this.targetVol = Math.abs(this.inout.outOvm);
          this.diffOvm = Math.abs(this.inout.outOvm); // 양수
          this.volPercent = Math.round(this.diffOvm / this.pk.ofm * 100);
          break;
        case 'O':
          this.statusVariant = 'warning';
          this.targetVol = this.inout.targetVol;
          this.diffOvm = Math.abs(this.inout.outOvm); // 양수
          this.volPercent =  Math.round(this.inout.outOvm / this.targetVol * 100)
          if( this.targetVol>0 && this.volPercent >= 100 ) speech(`${this.tnm} 출고량 확인.`)
          break;
        case 'D':
          this.statusVariant = 'warning';
          this.targetVol = Math.abs(this.inout.inOvm);
          this.diffOvm = Math.abs(this.inout.inOvm); // 양수
          this.volPercent = Math.round(this.diffOvm / this.tank.tankVolume * 100);
          break;
        case 'I':
          this.statusVariant = 'danger';
          this.targetVol = this.inout.targetVol;
          this.diffOvm = this.inout.inOvm;
          this.volPercent =  Math.round(this.inout.inOvm / this.targetVol * 100 );
          if( this.targetVol>0 && this.volPercent >= 100 ) speech(`${this.tnm} 입고량 확인.`)
          break;
        case 'N':
          this.statusVariant = 'info';
          this.targetVol = 0;
          this.diffOvm = 0;
          this.volPercent = 0;
          break;
        default:
          // this.statusMsg = '미확인';
          this.statusVariant = 'dark';
          break;
      }
    },


    async getInout(){
      const rs = await apiCall('get', `/api/inout/current/${this.tank.tid}`);
      if(rs.code===200){
        this.inout = rs.result;
        if( this.inout.sts!=='N') this.inputTargetVol = (this.volUnit==='g')? Math.round(this.inout.targetVol*LTR2GL):this.inout.targetVol;
        console.log( 'inout result -----> tid=',this.tid,'--->', rs.result  );
      }
      // await this.toastInfo(`입출고 상태 정보 업데이트`, 'info');
    },

    async getTankSts(){
      const rs = await apiCall('get', `/api/tank/status/${this.tank.tid}`);
      if(rs.code===200){
        this.tankSts = rs.result?rs.result: { io: 'N', ioAt: null };
        const {io} = this.tankSts;

        if( io==='I' || io==='O' ) {
          this.inputTargetVol = (this.volUnit === 'g') ? Math.round(this.inout.targetVol * LTR2GL) : this.inout.targetVol;
        }

        console.log( 'tank status result -----> tid=',this.tid,'--->', rs.result  );
      }
      // await this.toastInfo(`입출고 상태 정보 업데이트`, 'info');
    },



    async setInout( gbn ){
      let cmd = 'B';
      let cmdName = '시작';
      try{
        console.log( "setInout() ---- inout ----------->", this.inout );
        console.log( "setInout() ---- gbn ----------->", gbn );

        let inoutStr = IoStsMap[gbn];

        if(gbn==='N'){
          if( !await this.confirmModal(`${this.tnm} ${inoutStr} 작업 취소 확인`, `${inoutStr} 작업 취소`) ) return;
          cmd = 'C';
          cmdName = '취소'
        }else if(this.inout?.sts!=='N') {
          cmd = 'E'
          cmdName = '종료';
        }

        const param = {
          reqSts: gbn,
          cmd: cmd
        }
        console.log( 'setInout sts --->', gbn, param )

        const rs = await apiCall('post', `/api/inout/${this.tank.tid}`, param);
        console.log( 'inout result ---------->', rs.result  );
        if(rs.code===200){
          this.inout = rs.result;
          this.inputTargetVol = 0;
          this.setInoutSts(this.inout);

          const msg = `${this.tnm}, ${inoutStr} ${cmdName}, 설정 완료`;
          await this.toastInfo(msg , '경고');
          speech(msg);

        }else{
          console.log( rs.message );
          await this.toastWarn(rs.message, '경고');
        }
      }catch(err){
        console.log(err);
        await this.toastError(err);
      }
    },

    async setInoutVolume(){
      try{

        const gbn = this.inout.sts;
        if(!this.inputTargetVol){
          return await this.toastInfo('기준량 입력 오류 ', '경고');
        }

        const inoutStr = (gbn==='I')? '입고':'출고';
        const unitStr = (this.volUnit==='g')? '갤런으로' : '리터로';

        const targetVol = Number(this.inputTargetVol);

        const param = {
          cmd : 'V',
          reqSts: gbn,
          volume: targetVol,
          unit: this.volUnit
        }

        console.log( 'setInout sts --->', gbn, param )
        const rs = await apiCall('post', `/api/inout/${this.tank.tid}`, param);
        console.log( 'inout result ', rs.result  );

        if(rs.code===200){
          this.inout = rs.result;
          this.setTankStatus(this.inout);
          const msg = `탱크 ${this.tnm}, ${inoutStr} 작업 기준량, ${this.inputTargetVol} ${unitStr} 설정 되었습니다.`;
          await this.toastInfo(msg, '경고');
          speech(msg)
        }else{
          console.log( rs.message );
          await this.toastWarn(rs.message, '경고');
        }

      }catch(err){
        console.error(err);
      }
    },


    toggleDetail(){
      // this.windowHeight = window.outerHeight;
      // this.windowWidth = window.outerWidth;
      this.detailTmShow = !this.detailTmShow;
      /*if(this.detailTmShow){
        window.resizeTo( this.windowWidth, this.windowHeight+170)
      }else{
        window.resizeTo( this.windowWidth, this.windowHeight-170)
      }*/
    },



  },

  watch:{
    tlgPacket(newTp, oldTp) {
      // console.log( 'new packet ---', newTp)
      // console.log( 'old packet ---', oldTp)
      this.renderVolume( newTp );

    },
    'tank': function() {
      console.log( 'change tank --->', this.tank );
      this.init();
    }

  },
  beforeDestroy(){
    // using "removeListener" here, but this should be whatever $socket provides
    // for removing listeners
    // this.destroyObjects();
  },



}
</script>
